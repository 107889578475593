var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accept-goods-check"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "来货入库",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('van-search', {
    staticClass: "my-van-search-css",
    attrs: {
      "placeholder": "请输入供应商名称",
      "show-action": ""
    },
    model: {
      value: _vm.search_key,
      callback: function callback($$v) {
        _vm.search_key = $$v;
      },
      expression: "search_key"
    }
  }, [_c('div', {
    attrs: {
      "slot": "action"
    },
    on: {
      "click": _vm.handleSearch
    },
    slot: "action"
  }, [_vm._v(" 搜索 ")])]), _c('div', {
    staticClass: "cell-wrap"
  }, [_c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm._l(_vm.lists, function (item, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "title": item.supplier && item.supplier.name
      }
    }, [_c('div', {
      staticStyle: {
        "color": "red"
      },
      attrs: {
        "slot": "default"
      },
      slot: "default"
    }, [_vm._v(" ￥" + _vm._s(item.amount) + " ")])]);
  }), _vm.lists.length === 0 ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }