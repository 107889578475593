<template>
  <div class="accept-goods-check">
    <my-nav-bar
      title="来货入库"
      left-text="返回"
      left-arrow
    />
    <van-search v-model="search_key" placeholder="请输入供应商名称" show-action class="my-van-search-css">
      <div slot="action" @click="handleSearch">
        搜索
      </div>
    </van-search>
    <div class="cell-wrap">
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell v-for="(item,index) in lists" :key="index" :title="item.supplier && item.supplier.name">
          <div slot="default" style="color:red">
            ￥{{ item.amount }}
          </div>
        </van-cell>
        <van-empty v-if="lists.length === 0" description="暂无数据" />
      </van-list>
    </div>

  </div>
</template>

<script>
import MyNavBar from '../../components/my-nav-bar.vue'
import { getTotalAcceptData, getEmployeeTotalAcceptData } from '@/api/statistics'
export default {
  components: {
    MyNavBar
  },
  data() {
    return {
      search_key: '',
      page: 0,
      loading: false,
      finished: false,
      lists: [],
      limit: 10
    }
  },
  methods: {
    init() {
      this.page = 0
      this.loading = false
      this.finished = false
      this.lists = []
      this.$refs.list.check()
    },
    onLoad() {
      this.page++
      this.getLists()
    },
    getLists() {
      if (this.$route.query.is_employee) {
        const params = {
          limit: this.limit,
          page: this.page,
          name: this.search_key,
          date: this.$route.query.time
        }
        this.beginLoad()
        getEmployeeTotalAcceptData(params).then(res => {
          this.lists = this.lists.concat(res.data.list)
          this.loading = false
          this.search_key = ''
          if (res.data.list.length < this.limit) this.finished = true
          this.endLoad()
        })
      } else {
        const params = {
          limit: this.limit,
          page: this.page,
          name: this.search_key,
          type: this.$route.query.type,
          date: this.$route.query.time,
          store_id: this.$route.query.store_id
        }
        this.beginLoad()
        getTotalAcceptData(params).then(res => {
          this.lists = this.lists.concat(res.data.list)
          this.loading = false
          this.search_key = ''
          if (res.data.list.length < this.limit) this.finished = true
          this.endLoad()
        })
      }
    },
    handleSearch() { // 点击搜索
      this.init()
    }
  }

}
</script>

<style lang="scss" scoped>
.accept-goods-check{
  .my-van-search-css{
    position: fixed;
    left: 0;
    right: 0;
    top: 46px;
    z-index: 999;
  }
  .cell-wrap{
    margin-top: 100px;
    padding: 0 15px;
    overflow: hidden;
    .van-cell{
      margin-top: 10px;
    }
  }
}
</style>
